import { Nft, PreRelease } from "@/shared/models";
import { useEffect, useState } from "react";
import { useLoginContext } from "../redux/selectors";
import { checkOwner } from "../utils/checkOwner";

const useIsOwnerOfToken = (nft: Nft | PreRelease, userPage: boolean) => {
  const [isOwner, setIsOwner] = useState(false);

  const isNft = "type" in nft && "nftCopy" in nft;

  const {
    loginContext: { user },
  } = useLoginContext();

  useEffect(() => {
    const checkOwnerFunction = async () => {
      if (isNft) {
        const result = await checkOwner(
          nft.artist.nftAddress,
          nft.artist.chain,
          nft.nftCopy.tokenId,
          user?.kloovWallet ?? null
        );
        setIsOwner(result);
      } else {
        setIsOwner(true);
      }
    };
    if (!userPage) {
      return;
    }
    checkOwnerFunction();
  }, []);

  return { isOwner };
};

export default useIsOwnerOfToken;

import { FC, useEffect } from "react";
import Image from "next/image";
import { Nft } from "@/shared/models";
import useNftOpenSeaUrl from "../hook/useNftOpenSeaUrl";
import Link from "next/link";
import { getNftUrl, getTransferNftUrl } from "@/shared/utils/createUrls";
import classNames from "classnames";
import useClipboard from "../hook/useClipboard";
import useUserAgent from "../hook/useUserAgent";

interface Props {
  nft: Nft;
  isOpen: boolean;
  isOwner: boolean;
  setIsOpen: (value: boolean) => void;
}

const NftCardMenu: FC<Props> = ({ nft, isOpen, isOwner, setIsOpen }) => {
  const { copyLink } = useClipboard();
  const { nftOpenseaUrl } = useNftOpenSeaUrl(nft);
  const { isMobile } = useUserAgent();

  useEffect(() => {
    const closeModal = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      if (isOpen && !target.closest("#menu")) {
        setIsOpen(false);
      }
    };

    document.body.addEventListener("mousedown", closeModal);
    return () => document.body.removeEventListener("mousedown", closeModal);
  }, [isOpen, setIsOpen]);

  const _class = classNames(
    "absolute flex flex-col mx-6 px-6 gap-5 py-4 rounded-md bg-gray-450 right-0 z-10",
    isOwner ? "bottom-[-150px]" : "bottom-[-80px]",
    isMobile && "bottom-[80px]"
  );

  return (
    <>
      <ul id="menu" className={_class}>
        <li>
          <Link passHref href={nftOpenseaUrl}>
            <a target="_blank">
              <span className="flex gap-4">
                <Image
                  src="/static/icons/external_link.png"
                  alt="link icon"
                  height={20}
                  width={20}
                  objectFit="cover"
                  className="object-cover"
                />
                View on Opensea
              </span>
            </a>
          </Link>
        </li>

        {isOwner && (
          <li>
            <Link
              passHref
              href={getTransferNftUrl(nft.slug, nft.nftCopy.tokenId)}
            >
              <a>
                <span className="flex gap-4">
                  <Image
                    src="/static/icons/transfer.svg"
                    alt="link icon"
                    height={20}
                    width={20}
                    objectFit="cover"
                    className="object-cover"
                  />

                  <span>Transfer to Metamask</span>
                </span>
              </a>
            </Link>
          </li>
        )}

        <li>
          <span
            title="Copy to clipboard"
            onClick={(e) =>
              copyLink(e, `${window.location.host}${getNftUrl(nft)}`)
            }
            className="flex gap-4 cursor-pointer"
          >
            <Image
              src="/static/icons/link.png"
              alt="link icon"
              height={20}
              width={20}
              objectFit="cover"
              className="object-cover"
            />

            <span>Copy Link</span>
          </span>
        </li>
      </ul>
    </>
  );
};

export default NftCardMenu;

import { KlipMedia } from "@/shared/models";

export type ActionType = ReturnType<typeof setKlip | typeof clearKlip>;

export const setKlip = (klip: KlipMedia) => {
  return {
    type: "SET_KLIP" as const,
    payload: {
      klip,
    },
  };
};

export const clearKlip = () => {
  return {
    type: "CLEAR_KLIP" as const,
    payload: null,
  };
};

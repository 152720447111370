import { ethers } from "ethers";
import ArtistData from "../../../artifacts/contracts/ArtistV4.sol/ArtistV4.json";
import { getDefaultProvider } from "@/backend/deployments/helpers";

export const checkOwner = async (
  nftAddress: string,
  artistChain: string,
  tokenId: number,
  walletAddress: string | null
) => {
  const artist = new ethers.Contract(
    nftAddress,
    ArtistData.abi,
    getDefaultProvider(artistChain)
  );
  return (await artist.ownerOf(tokenId)) === walletAddress;
};

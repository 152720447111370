import { useIsProduction } from "./useIsProduction";
import getNetwork from "../../shared/utils/getNetwork";
import { PreRelease, Nft } from "@/shared/models";
import {
  OPEN_SEA_DEV_URL,
  OPEN_SEA_PROD_URL,
} from "@/shared/constants/openSeaUrls";

const useNftOpenSeaUrl = (
  nft: Pick<Nft, "name" | "artist" | "nftCopy"> | null,
  preRelease?: PreRelease
) => {
  const isProduction = useIsProduction();
  if (!nft) {
    if (preRelease && preRelease.fanCardCopy) {
      const preReleaseUrlPath = `${preRelease.fanCard.nftAddress}/${preRelease.fanCardCopy.tokenId}`;
      const PROD_URL = `${OPEN_SEA_PROD_URL}/assets`;
      const DEV_URL = `${OPEN_SEA_DEV_URL}/assets`;
      const nftOpenseaUrl = isProduction
        ? `${PROD_URL}/ethereum/${preReleaseUrlPath}`
        : `${DEV_URL}/${
            getNetwork(preRelease.artist.chain).openseaName
          }/${preReleaseUrlPath}`;
      return {
        nftOpenseaUrl,
      };
    } else {
      return {
        nftOpenseaUrl: "",
      };
    }
  } else {
    const klipUrlPath = `${nft.artist.nftAddress}/${nft.nftCopy.tokenId}`;
    const PROD_URL = `${OPEN_SEA_PROD_URL}/assets`;
    const DEV_URL = `${OPEN_SEA_DEV_URL}/assets`;
    const nftOpenseaUrl = isProduction
      ? `${PROD_URL}/ethereum/${klipUrlPath}`
      : `${DEV_URL}/${getNetwork(nft.artist.chain).openseaName}/${klipUrlPath}`;
    return {
      nftOpenseaUrl,
    };
  }
};

export default useNftOpenSeaUrl;

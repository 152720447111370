export const getItemTypeIcon = (type: string, size = "small") => {
  const itemType = type.toLocaleLowerCase();
  switch (itemType) {
    case "klip":
      return size === "small"
        ? "/static/icons/items/klips.svg"
        : "/static/icons/items/klips-lg.svg";
    case "klips":
      return size === "small"
        ? "/static/icons/items/klips.svg"
        : "/static/icons/items/klips-lg.svg";

    case "avants":
      return size === "small"
        ? "/static/icons/items/avants.svg"
        : "/static/icons/items/avants-lg.svg";

    case "fulltrack":
      return size === "small"
        ? "/static/icons/items/xclusivs.svg"
        : "/static/icons/items/xclusivs-lg.svg";

    case "xclusivs":
      return size === "small"
        ? "/static/icons/items/xclusivs.svg"
        : "/static/icons/items/xclusivs-lg.svg";

    case "basics":
      return size === "small"
        ? "/static/icons/items/basics.svg"
        : "/static/icons/items/basics-lg.svg";
    default:
      return null;
  }
};

import { Nft, NftType } from "@/shared/models";
import classNames from "classnames";
import Image from "next/image";
import React, { FC, useMemo, useState } from "react";
import Moment from "react-moment";
import Link from "next/link";
import useCountdown from "@/client/hook/useCountdown";
import { getArtistUrl, getNftUrl } from "@/shared/utils/createUrls";
import { DEFAULT_IMAGE_PLACEHOLDER } from "@/shared/constants/path";
import { getAvatar, getCDNUrl } from "@/shared/utils/images";
import { TWO_DAYS } from "@/shared/constants/time";
import NftCardMenu from "./NftCardMenu";
import { BsThreeDots } from "react-icons/bs";
import { useRouter } from "next/router";
import useIsOwnerOfToken from "../hook/useIsOwnerOfToken";
import { useDispatch } from "react-redux";
import { setKlip } from "../redux/actions/klip";
import { getItemTypeIcon } from "../utils/icons";
import Verified from "./icons/Verified";

interface Props {
  nft: Nft;
  itemId?: number;
  displayCountDown?: boolean;
  displayNftNumber?: boolean;
  artistPage?: boolean;
  userPage?: boolean;
  className?: string;
  displayArrow?: boolean;
  artistPreview?: boolean;
}

const NftCard: FC<Props> = ({
  nft,
  className,
  displayCountDown,
  displayNftNumber = false,
  artistPage = false,
  userPage = false,
  displayArrow = false,
  artistPreview = false,
}) => {
  const router = useRouter();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const { isOwner } = useIsOwnerOfToken(nft as Nft, userPage);
  const dispatch = useDispatch();

  const { isLoadedCountdown, startCountdown, isStartCountDownActive } =
    useCountdown(nft.startDate, nft.endDate, false);

  const isTransferPage: boolean = useMemo(() => {
    return router.pathname.includes("transfer-nft");
  }, [router.pathname]);

  const remainingNft = nft.nftCount - nft.nftCountSold;

  const _class = classNames(
    className,
    `flex flex-col space-y-5 ${
      artistPage ? "h-[400px]" : "h-[450px]"
    } w-[292px] border-2 border-[#282829] bg-gray-card px-6 py-4 rounded-sm relative`
  );

  const _typeClass = classNames(
    "bg-klip rounded-lg text-13 font-inter text-white min-w-max",
    nft.type === "fulltrack" ? "p-2 " : "py-2 px-4"
  );

  const goToNftPage = () => {
    const nftUrl = getNftUrl(nft);

    if (userPage) {
      if ("type" in nft && nft.type === NftType.KLIP) {
        dispatch(setKlip(nft.klips[0]));
      }
    }
    router.push(nftUrl);
  };

  const getNftType = () => {
    if (nft.type === NftType.FULLTRACK) return "Xclusivs";
    if (nft.type === NftType.KLIP) return "Klips";
  };

  const getFormattedDate = () => {
    return (
      <>
        <Moment
          className="font-semibold"
          format="dddd"
          date={new Date(nft.nftCopy.sellDate)}
        />
        <Moment format="DD MMM" date={new Date(nft.nftCopy.sellDate)} />
      </>
    );
  };

  return (
    <section className={_class}>
      <div className="flex flex-row justify-between items-center">
        {/* User page always shows format date.
            First nft shows timer (countDown).
            Shows now on sale if there's no timer or sold out if remainingNft is zero
        */}
        {userPage && nft.nftCopy.sellDate ? (
          <span className="flex flex-row items-center text-sm font-inter leading-tight text-white break-words gap-x-1">
            {getFormattedDate()}
          </span>
        ) : isStartCountDownActive ? (
          startCountdown?.timerSeconds ? (
            displayCountDown &&
            parseInt(startCountdown?.timerHours) < TWO_DAYS ? (
              <p className="text-xl font-poppins font-normal uppercase text-white">
                {startCountdown?.timerHours}: {startCountdown?.timerMinutes}:{" "}
                {startCountdown?.timerSeconds}
              </p>
            ) : (
              <div className="flex flex-row items-center text-sm font-inter leading-tight text-white break-words gap-x-1">
                {getFormattedDate()}
              </div>
            )
          ) : (
            <div></div>
          )
        ) : (
          <div className="flex flex-row items-center text-sm font-poppins leading-tight text-white break-words">
            {!nft.isOpenEdition && remainingNft === 0
              ? "Sold out"
              : "Now on sale"}
          </div>
        )}

        {!artistPreview && (
          <>
            <Image
              src={getItemTypeIcon(nft.type, "large") ?? ""}
              width={100}
              height={32}
              alt={`${nft.id}-icon-item`}
            />
          </>
        )}
      </div>
      <div className="w-[244px] h-[244px]  relative flex flex-col flex-grow-0 items-center justify-center">
        <button onClick={goToNftPage}>
          <Image
            src={getCDNUrl(nft.cover)}
            alt={`${nft.name} image`}
            layout="fill"
            blurDataURL={DEFAULT_IMAGE_PLACEHOLDER}
            className="rounded-md object-cover"
          />
          {!artistPreview && (
            <Image
              src="/static/icons/play.svg"
              alt="play icon"
              width={40}
              height={40}
            />
          )}
        </button>
      </div>

      <div className="flex flex-col space-y-3">
        <div className="flex flex-col space-y-4">
          <Link passHref href={getNftUrl(nft as Nft)}>
            <a>
              <h3
                title={nft.name}
                className="text-lg font-inter leading-normal text-white whitespace-nowrap truncate"
              >
                {nft.name}
              </h3>
            </a>
          </Link>

          {!artistPage && (
            <Link passHref href={getArtistUrl(nft.artist.username)}>
              <a className="flex flex-row items-center justify-between">
                <div className="flex flex-row space-x-2 items-center">
                  <div className="w-6 h-6 relative">
                    <Image
                      src={getAvatar(nft.artist.avatar)}
                      alt={`${nft.artist.username} image`}
                      layout="fill"
                      objectFit="cover"
                      className="rounded-full object-cover"
                      blurDataURL={DEFAULT_IMAGE_PLACEHOLDER}
                    />
                  </div>

                  <div className="flex flex-col w-max mr-2">
                    <h4
                      title={nft.artist.name}
                      className="text-15 font-poppins leading-tight font-semibold whitespace-nowrap truncate uppercase text-white"
                    >
                      {nft.artist.name}
                    </h4>
                  </div>

                  <div>
                    <Verified size={14} />
                  </div>
                </div>

                {displayArrow && (
                  <div>
                    <Link passHref href={getNftUrl(nft)}>
                      <a>
                        <div className="flex items-center justify-between">
                          <Image
                            src="/static/icons/arrow-up-right.svg"
                            height={38}
                            width={38}
                            alt="green arrow icon"
                            className="object-contain"
                          />
                        </div>
                      </a>
                    </Link>
                  </div>
                )}
              </a>
            </Link>
          )}
        </div>

        <div className="flex justify-between">
          {displayNftNumber && (
            <div className="flex flex-row space-x-1 items-center font-poppins leading-tight text-15 text-gray-750">
              <span>#{nft.nftCopy.copyId}</span>

              {!nft.isOpenEdition && (
                <>
                  <span>of</span>

                  <span>{nft.nftCount}</span>
                </>
              )}
            </div>
          )}
          <div className="flex justify-end w-full">
            {!isTransferPage && userPage && (
              <BsThreeDots
                className="cursor-pointer"
                size={24}
                onClick={(event) => {
                  event.preventDefault();
                  setMenuOpen((state) => !state);
                }}
              />
            )}
          </div>
        </div>
      </div>
      {menuOpen && (
        <NftCardMenu
          nft={nft}
          isOpen={menuOpen}
          isOwner={isOwner}
          setIsOpen={(value) => setMenuOpen(value)}
        />
      )}
    </section>
  );
};

export default NftCard;
